import { dev_trade_side_buy, dev_trade_side_sell } from './constants';

export const used_language = 'french';

export const HEADERS =
    {
        MARKET: Symbol("MARKET"),
        ORDER: Symbol("ORDER"),
        ACCURATE: Symbol("ACCURATE"),
        STRENGTH: Symbol("STRENGTH"),
        BALANCE: Symbol("BALANCES"),
        PAIR_PROFIT: Symbol("PAIR_PROFIT"),
    }; Object.freeze(HEADERS);

export const indicator_headers =
    {
        french:
            {
                [HEADERS.MARKET]: 'Marché',
                [HEADERS.ORDER]: 'Ordre',
                [HEADERS.ACCURATE]: 'Précision',
                [HEADERS.STRENGTH]: 'Force',
                [HEADERS.BALANCE]: 'Equilibre',
                [HEADERS.PAIR_PROFIT]: 'Profit de la paire',
            }
    }


// TODO Index as key ! :/
export const trade_headers =
    {
        french:
            [
                'Marché', //0
                'Ordre', //1
                // 'Précision',
                // 'Force',
                // 'Equilibre',
                "Date d'ouverture", // 2
                'Quantité', // 3
                'Prix', // 4
                'Date de fermeture', // 5
                'Quantité', // 6
                'Prix', // 7
                'Profit', // 8
                'Extra',// 9
                'Action',// 10
                'Statut', // 11

            ]
    }
export const trade_side_label =
    {
        french:
            {
                [dev_trade_side_sell]: 'VENTE',
                [dev_trade_side_buy]: 'ACHAT'
            }
    }

export const menu_labels =
    {
        french: ['Trades', 'Marchés', 'Stratégies', 'Statistiques', 'Comptes']
    };

export const titles = menu_labels;
export const avatars = ['T', 'M', 'S', 'S', 'C'];

export const ia_shutdown_button_labels =
    {
        french: ['Arrêter IA', 'Démarrer IA']
    };
export const ia_mode_labels =
    {
        french: ['Démarrer manuel', 'Arrêter manuel']
    }
export const IndicatorLabels =
    {
        french: ['Accepter', 'Annuler']
    }
export const marketTabs =
    {
        french: ['Mon portefeuille', 'Indice', 'Forex', 'Matière Première']
    }

export const statTabs =
    {
        french: ['Jour', 'Semaine', 'Mois']
    }

export const titleNotAllowedAction =
    {
        french: 'Action non permise'
    }

export const titleRestrictedAction =
    {
        french: 'Restriction du compte'
    }

export const marketMessageAlwaysOne =
    {
        french: 'Vous devez toujours avoir au moins un marché actif'
    }

export const marketMessageAbonnement =
    {
        french: "Ce marché n'est pas inclus dans votre abonnement"
    }

export const strategyMessageAlwaysOne =
    {
        french: 'Vous devez toujours avoir au moins une stratégie active'
    }

export const strategyMessageAbonnement =
    {
        french: "Cette stratégie n'est pas incluse dans votre abonnement"
    }
export const accountMessageAbonnement =
    {
        french: "Vous ne pouvez pas ajouter de compte dans votre abonnement"
    }

export const wrong_mdp =
    {
        french: "L'identifiant ou mot de passe est incorrect"
    }
export const no_mdp =
    {
        french: 'Veuillez entrer votre mot de passe'
    }
export const no_mail =
    {
        french: 'Veuillez entrer votre e-mail'
    }
export const label_understood =
    {
        french: "J'ai compris"
    }
export const label_connexion =
    {
        french: 'Connection'
    }

export const label_closed_notif =
    {
        french: 'FERMÉ'
    }

export const label_opened_notif =
    {
        french: 'OUVERT'
    }

export const no_notif_label =
    {
        french: 'Pas de Notification depuis que vous êtes connecté'
    }

export const ia_waiting =
    {
        french: 'En attente'
    }

export const total_balance_label =
    {
        french: 'Total Balance'
    }

export const no_trade_label =
    {
        french: 'Aucun trade'
    }
