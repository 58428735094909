import { marketConstants } from '../../constants';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import amber from '@material-ui/core/colors/amber';
import brown from '@material-ui/core/colors/brown';
import teal from '@material-ui/core/colors/teal';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/grey';
import {green, lightBlue} from "@material-ui/core/colors";


export const MarketsInfo =
    [
        {
            code: 'ETHUSDT_EOSUSDT',
            color: indigo[500],
            colorDisabled: indigo[100],
            sigle: 'E/E',
            long: 'ETHUSDT_EOSUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'BTCUSDT_BNBUSDT',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'B/B',
            long: 'ETHUSDT_EOSUSDT',
            foot: 'Crypto',
            type: 'Pair',
            disabled: false
        },

        {
            code: 'FDXM_FESX',
            color: indigo[400],
            colorDisabled: indigo[200],
            sigle: 'F/F',
            long: 'FDXM_FESX',
            foot: 'Futures',
            type: 'Pair',
            disabled: false
        },
    ];
// export const Market =
//     [
//         {
//             code: 'FDAX',
//             color: indigo[500],
//             colorDisabled: indigo[100],
//             sigle: 'F',
//             long: 'DAX 30',
//             foot: 'Futures',
//             type: 'Index',
//             disabled: false
//         },
//         {
//             code: 'NQ',
//             color: blue[500],
//             colorDisabled: grey[300],
//             sigle: 'N',
//             long: 'NASDAQ',
//             foot: 'Futures',
//             type: 'Index',
//             disabled: false
//         },
//         {
//             code: 'FDXM',
//             color: green[500],
//             colorDisabled: grey[300],
//             sigle: 'mF',
//             long: 'Mini FDAX',
//             foot: 'Futures',
//             type: 'Index',
//             disabled: false
//         },
//         {
//             code: 'MNQ',
//             color: teal[500],
//             colorDisabled: grey[300],
//             sigle: 'mN',
//             long: 'Mini FDAX',
//             foot: 'Futures',
//             type: 'Index',
//             disabled: false
//         },
//         {
//             code: 'ES',
//             color: red[500],
//             colorDisabled: grey[300],
//             sigle: 'E',
//             long: 'E-SP500',
//             foot: 'Futures',
//             type: 'Index',
//             disabled: true
//         },
//         {
//             code: 'GC',
//             color: amber[500],
//             colorDisabled: grey[300],
//             sigle: 'G',
//             long: 'GOLD',
//             foot: 'Futures',
//             type: 'Matiere Premiere',
//             disabled: true
//         },
//         {
//             code: 'CL',
//             color: brown[500],
//             colorDisabled: grey[300],
//             sigle: 'C',
//             long: 'CRUDE OIL',
//             foot: 'Futures',
//             type: 'Matiere Premiere',
//             disabled: true
//         },
//         {
//             code: '6E',
//             color: teal[500],
//             colorDisabled: grey[300],
//             sigle: '6E',
//             long: 'EURO FX',
//             foot: 'Futures',
//             type: 'Forex',
//             disabled: true
//         },
//         {
//             code: '6B',
//             color: cyan[500],
//             colorDisabled: grey[300],
//             sigle: '6B',
//             long: 'BRITISH POUND',
//             foot: 'Futures',
//             type: 'Forex',
//             disabled: true
//         }
//     ];

export const getAllMarkets = () =>
{
    // TODO : get them from server
    return {
        type: marketConstants.GET_MARKETS,
        payload: MarketsInfo
    }
}
