import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuDrawer from './Common/menuDrawer';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import axios from 'axios';
import {
    IA_MODE_AUTO_URL, IA_MODE_MANUAL_URL,
    IA_SHUTDOWN_URL,
    IA_START_URL,
} from '../constants';
import { getIAStatusFromServer, changeIAStatus, getModeStatus, changeIAMode } from '../components/ControlPanel/ControlPanelAction';

import IAButton from '../components/ControlPanel/IA/IAButton';
import ModeButton from "../components/ControlPanel/Mode/ModeButton"
import AlertContainer from "../components/Alerts/AlertContainer";

const styles = {
    grid: {
        margin: 50,
        width: "auto"
    },
    title: {
        fontSize: 25,
        fontVariant: "small-caps"
    }
};

class PrivateLayout extends Component
{


    // TODO Cela ne doit pas etre ainsi, mais plutot thunkified !
    handleShutdownButton(event) {
        event.preventDefault()
        this.props.ia.iaStatus
            ?
            axios.get(IA_SHUTDOWN_URL,{

                withCredentials: true
            }) :
            axios.get(IA_START_URL,
                {
                    withCredentials: true
                })
        this.props.changeIAStatus(!this.props.ia.iaStatus)
    }
    handleIaModeButton(event) {

        event.preventDefault()

        this.props.ia.isAutoMode
            ?
            axios.get(IA_MODE_MANUAL_URL,{

                withCredentials: true
            }) :
            axios.get(IA_MODE_AUTO_URL,
                {
                    withCredentials: true
                })


        this.props.changeIAMode(!this.props.ia.isAutoMode)


    }
    // -------------------------------

    componentDidMount() {

        this.props.getIAStatusFromServer()
        this.props.getModeStatus()

    }

    render() {
        const Component = this.props.component

        const {
            classes,
            route,
            title,
            avatar,
            color
        } = this.props


        return (
            <div>
                <MenuDrawer />
                <Grid container spacing={8} direction="column" justify="space-around" className={classes.grid}>
                    {/* TODO Generaliser le ControlPanel dans les components ! ;) ControlPanelContainer and Presentational */}
                    <Grid item>
                        <Card>
                            <CardHeader
                                classes={{
                                    title: classes.title,
                                }}
                                avatar={
                                    <Avatar aria-label={title} style={{backgroundColor: color}}>
                                        {avatar}
                                    </Avatar>
                                }
                                action={
                                    <div>

                                        <IAButton
                                            received_info={this.props.ia.known_status}
                                            active={this.props.ia.iaStatus}
                                            onclick={this.handleShutdownButton.bind(this)} />

                                        <ModeButton
                                            received_info={this.props.ia.known_mode_status}
                                            active={this.props.ia.isAutoMode}
                                            disabled={true}
                                            onclick={this.handleIaModeButton.bind(this)} />

                                    </div>
                                }
                                title={title}
                            />
                        </Card>
                    </Grid>
                    <Grid item>
                        <Component route={route} />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps({ ia }) {
    return { ia };
}
function mapDispatchToProps(dispatch){
    return bindActionCreators({ getIAStatusFromServer, getModeStatus, changeIAMode, changeIAStatus }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrivateLayout));
