import {IndicatorActionConstants, MarketsAllowed, websocketConstants} from '../../constants';
import round from 'round-to'
import * as _ from "lodash"
import axios from "axios"
import BigNumber from "bignumber.js";


// Les states  se feront mettre a jour a l'update !
// cf tradeContainer comments for more precision about the structure of data
function indicatorReducer (state = _.map(MarketsAllowed, code => ({ code, isActive : false, isRequested: false, isTradable : false, isInProgress: false     })), action)
{
    if(_.isNil(action.payload))
        return state

    switch(action.type) {
        case websocketConstants.PAIR_PROFIT_UPDATED:{


            return state.map(indicatorData =>
                indicatorData.code === action.payload.pairKey
                    ? {
                        ...indicatorData,
                        ...action.payload,
                        isActive: true,
                        isTradable: !action.payload.isAnyPositionOpenend
                            && action.payload.openState === 'DETECTED'
                            && state.find((indicatorData) => indicatorData.code === action.payload.pairKey).isRequested === false,
                        isInProgress: action.payload.openState === 'EXECUTED'
                    }
                    : indicatorData)

        }

        case websocketConstants.NEW_POSITION_DETECTED: {
            return state.map(indicatorData =>
                indicatorData.code === action.payload.pairKey ?
                    ({...indicatorData,  isRequested: false ,  isTradable: true}) : indicatorData)
        }

        case IndicatorActionConstants.ACCEPT_POSITION_DETECTED :
        case IndicatorActionConstants.CANCEL_POSITION_DETECTED :
        case websocketConstants.POSITION_DETECTED_ACCEPTED :
        case websocketConstants.POSITION_DETECTED_CANCELED  :
        {
            return state.map(indicatorData =>
                indicatorData.code === action.payload.pairKey ?
                    {...indicatorData, isRequested : true, isTradable: false  } : indicatorData)
        }

        case websocketConstants.ERROR: {
            return state.map(indicatorData =>
                indicatorData.code === action.payload.code ?
                    {...indicatorData, isRequested : true, isTradable: false  } : indicatorData)
        }


        default:
            return state;
    }
}

export default indicatorReducer;
