import axios from 'axios';
import {ControlPanelConstants, IA_STATUS_URL, IA_MODE_STATUS_URL,} from '../../constants';





//TODO Bugg : #1 Request en pending #2 process.env non acessible
export const getIAStatusFromServer = async () => {

    const request = await axios.get(IA_STATUS_URL,{withCredentials: true})

    return{
        type: ControlPanelConstants.GET_IA_STATUS,
        payload: request
    }
}


export const getModeStatus = async () =>
{
    const request = await axios.get(IA_MODE_STATUS_URL,{withCredentials: true})


    return{
        type: ControlPanelConstants.GET_IA_MODE,
        payload: request
    }
}



export const changeIAStatus = (iaStatus) =>
{
    // Only changes the local save of ia. The request is made before that.
    return {
        type: ControlPanelConstants.CHANGE_IA_STATUS,
        payload: iaStatus
    }
}


export const changeIAMode = (iaStatus) =>
{
    // Only changes the local save of reverse ia. The request is made before that.
    return {
        type: ControlPanelConstants.CHANGE_IA_MODE,
        payload: iaStatus
    }
}
